.css-1s2u09g-control{
    background-color: unset!important;
    color:#65676b;
    padding: 0 18px;
    font-size: 16px;
    font-weight: 400;
    border-radius:10px;
    border:2px solid hsla(0, 0%, 100%, 0.1);
}
.css-1pahdxg-control{
    background-color: unset!important;
    border-radius:15px!important;
    border-color: hsla(0, 0%, 100%, 0.1)!important;
    color:#acacac!important;
}
.css-1s2u09g-control:active{
    background-color: unset!important;
    border-radius:15px!important;
    border-color: hsla(0, 0%, 100%, 0.1)!important;
    color:#acacac!important;
}


.css-1s2u09g-control{
    border-radius:15px!important;
    border-color: hsla(0, 0%, 100%, 0.1)!important;
    color:#acacac!important;
}

.css-14el2xx-placeholder{
    border-radius:15px!important;
    border-color: hsla(0, 0%, 100%, 0.1)!important;
    color:#acacac!important;
}

.light .css-1s2u09g-control{
    background-color: unset!important;
    border-radius:15px!important;
    border-color: rgba(0, 0, 0, 0.1411764706)!important;
    color:#65676b;
    padding: 0 18px;
    font-size: 16px;
    font-weight: 400;
    border-radius:10px;
    border:2px solid hsla(0, 0%, 100%, 0.1);
}
